import "./scss/style.scss";

import "./js/inc/_jquery-global.js";
import "./js/inc/lite-yt-embed.js";
import 'slick-carousel';

window.addEventListener("load", () => {

  // アンカーリンク 位置調整
  $(function(){
    $('a[href^="#"]').click(function() {
      let speed = 400;
      let type = 'swing';
      let href= $(this).attr("href");
      let target = $(href == "#index" ? 'html' : href);
      let position = target.offset().top - 30;
      $('body,html').animate({scrollTop:position}, speed, type);
      return false;
    });
  });

  // スマホメニュー表示・非表示
  const menuButton = document.querySelector(".js-navBtn");
  const headerNav = document.querySelector(".js-headerNav");
  const headerNavLinks = document.querySelectorAll(".js-headerNavLink");
  const headerBackground = document.querySelector(".js-headerBg");
  const bodyElement = document.querySelector("body");
  const menuTL = gsap.timeline();
  const toggleNav = () => {
    menuButton.classList.toggle("is-open");
    headerNav.classList.toggle("is-open");
    headerBackground.classList.toggle("is-open");
    bodyElement.classList.toggle("is-open");
  };
  const removeNav = () => {
    menuButton.classList.remove("is-open");
    headerNav.classList.remove("is-open");
    headerBackground.classList.remove("is-open");
    bodyElement.classList.remove("is-open");
  };
  menuButton.addEventListener("click", () => {
    if(menuButton.classList.contains('is-open')) {
      removeNav();
      menuTL
      .fromTo('.header__navItem', {autoAlpha:1}, {autoAlpha:0});
    } else {
      toggleNav();
      menuTL
      .fromTo('.header__navItem', {autoAlpha:0,y:10},{autoAlpha:1,y:0,delay:.5,stagger:.025}, '-=.1');
    }
  });
  headerNavLinks.forEach((navLink) => {
    navLink.addEventListener("click", removeNav);
  });
  headerBackground.addEventListener("click", removeNav );

  // subfvTitle 文字列分割・カラークラス付与
  const subfvTitles = document.querySelectorAll('.js-subfvTitle');
  if(subfvTitles) {
    subfvTitles.forEach(subfvTitle => {
      let newText = '';
      const text = subfvTitle.textContent;
      const result = text.split('');      
      for (let i = 0; i < result.length; i++) {
        newText += '<span>' + result[i] + '</span>';
      }
      subfvTitle.innerHTML = newText;
  
      const parentElement = document.querySelector('.en.js-subfvTitle');
      const childElements = Array.from(parentElement.childNodes)
        .filter(node => node.nodeType === Node.ELEMENT_NODE && node.textContent.trim() !== '');
      
      const classNames = ['u-color-blue', 'u-color-pink', 'u-color-skyblue', 'u-color-yellow'];
      childElements.forEach((element, index) => {
        if (index % 2 !== 0) {
          const classIndex = Math.floor(index / 2) % classNames.length;
          const className = classNames[classIndex];
          element.classList.add(className);
        }
      });
    });
  }

  // fvスライダー
  const fvSlider = document.querySelector('.js-fvSlider');
  if(fvSlider) {
    $(".js-fvSlider").not(".slick-initialized").on("init", function (event, slick) {
      const count = slick.slideCount;
      if (count === 1) {
        $(".js-fvSlider").addClass("is-only");
      }
    })
    .slick({
      pauseOnHover: false,
      slidesToScroll: 1,
      centerMode: true,
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: true,
      dots: true,
      centerPadding: "5%",
      prevArrow: `<button class="slider-arrow slider-prev"><i class="fa-solid fa-caret-left"></i></button>`,
      nextArrow: `<button class="slider-arrow slider-next"><i class="fa-solid fa-caret-right"></i></button>`,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerPadding: "6%",
          }
        }
      ]
    });
  }

  // NEWS タブ
  const tabs = document.querySelectorAll('.js-tab');
  const tabContents = document.querySelectorAll('.js-tabContent');
  if(tabs) {
    tabs.forEach(function(tabListItem) {
      tabListItem.addEventListener('click', function() {
        const index = Array.prototype.indexOf.call(tabs, this);
  
        tabs.forEach(function(tab) {
          tab.classList.remove('is-tab-active');
          tab.setAttribute("aria-selected", false);
        });
        this.classList.add('is-tab-active');
        this.setAttribute("aria-selected", true);
  
        tabContents.forEach(function(tabContent) {
          tabContent.classList.remove('is-tab-active');
          tabContent.setAttribute("hidden", true);
        });
        tabContents[index].classList.add('is-tab-active');
        tabContents[index].removeAttribute("hidden");
      });
    });
  }

  // キャンパスマップ アコーディオン
  const campusMap = document.querySelectorAll(".js-campusMap");
  if(campusMap) {
    $(".js-campusMap .js-campusMapBody").first().css("display", "block");
    $(".js-campusMap .js-campusMapBody").first().addClass("is-active");
    $(".js-campusMap .js-campusMapHead").first().addClass("is-active");

    $(".js-campusMapHead").on("click", function () {
      $(".js-campusMapHead").not(this).next().slideUp(400);
      $(".js-campusMapHead").not(this).next().removeClass("is-active");
      $(".js-campusMapHead").not(this).removeClass("is-active");
      $(this).next().slideToggle(400);
      $(this).next().toggleClass("is-active");
      $(this).toggleClass("is-active");
    });
  }

  // キャンパスマップ 解説ブロック アコーディオン
  const campusPlaceHead = document.querySelectorAll(".js-campusPlaceHead");
  if(campusPlaceHead) {
    $(".js-campusPlaceHead").on("click", function () {
      $(this).next().slideToggle(300);
      $(this).next().toggleClass("is-active");
      $(this).toggleClass("is-active");
    });
  }

  // モーダルウィンドウ
  const modal = document.querySelectorAll(".modal");
  if(modal) {
    MicroModal.init({
      openClass: 'is-modalOpen',
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      disableFocus: true,
    });
  }
});
